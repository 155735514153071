/* global  */ // tUrl等のグローバル変数を宣言する
// import Swiper from 'swiper' // Swiper
// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))

jQuery(function ($) {
  /**
   * ナビホバーアクション
   * 画像パス_offを_onに置き換える
   */
  $('nav img').hover(
    function () {
      $(this).attr('src', $(this).attr('src').replace('_off', '_on'))
    },
    function () {
      if (!$(this).hasClass('currentPage')) {
        $(this).attr('src', $(this).attr('src').replace('_on', '_off'))
      }
    }
  )

  /**
   * アコーディオン
   */

  $('.accordion dt').on('click', function () {
    $(this).next().slideToggle()
    $(this).toggleClass('active')
  })
  $('.btn-sp-nav').on('click', function () {
    $(this).parents('.l-navi-sp').next('.pullbox').children('.pull').slideToggle()
    $(this).toggleClass('active')
  })
})
